import { useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "../style/ConfessionBooth.module.css";
import starIcon from "../assets/Icons/noun_sparkling.svg";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { grey } from "@mui/material/colors";

function ConfessionBooth() {
  const navigate = useNavigate();

  const [confession, setConfession] = useState("Tap to write!");

  function onSubmit() {
    //api call to submit confession
    if (checkInput(confession) === false) {
      fetch(`${window.apiUrl}/confessions/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          confession: confession,
          room: {
            RoomId: 6236,
          },
        }),
      });
      console.log(confession);
      navigate("/");
      localStorage.setItem("userLoggedin", "true");
    } else {
      window.alert("Please enter a confession!");
    }
  }

  //loop to check if input value is empty or base value or empty string
  function checkInput(confession) {
    if (confession === "Tap to write!" || confession === "") {
      return true;
    } else {
      return false;
    }
  }

  function onClick() {
    navigate("/");
  }

  return (
    <div className={style.background}>
      <CloseRoundedIcon
        className={style.wineIcon}
        sx={{
          color: grey[50],
          fontSize: 50,
          background: grey[0],
          borderRadius: 25,
        }}
        onClick={onClick}
      />
      <div className={style.container}>
        <div className={style.titleContainer}>
          <h1>
            MAKE <br /> YOUR
            <img src={starIcon} alt="star" className={style.starIcon} /> <br />
            CONFESSION
          </h1>
        </div>
        <div className={style.inputContainer}>
          <textarea
            className={style.textArea}
            onChange={(e) => {
              setConfession(e.target.value);
            }}
            value={confession}
            onFocus={(e) => {
              setConfession("");
            }}
          />
          <button className={style.submit} type="submit" onClick={onSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfessionBooth;
