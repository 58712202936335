import style from "../../style/Obj1.module.css";
import starIcon from "../../assets/Icons/noun_sparkling.svg";
import { useNavigate } from "react-router-dom";
import NavBarAlt from "../NavBarAlt";

export default function Rules2() {
  let navigate = useNavigate();
  function handleClickBack() {
    navigate("/rules/01");
  }

  return (
    <div className={style.obj1root} id="nav-root">
      <NavBarAlt />
      <div className={style.container}>
        <div className={style.titleContainerRules}>
          <h1 className={style.titleRules}>
            THE 10 <br />
            COMMAND- <br />
            MENTS
            <img src={starIcon} alt="star" className={style.starIcon} /> <br />
          </h1>
        </div>
        <div className={style.buttonContainerBack}>
          <button className={style.backButton} onClick={handleClickBack}>
            <p className={style.back}>Back</p>
          </button>
        </div>
        <div className={style.rulesContainer}>
          <ul>
            <li className={style.rulesLine}>
              <div className={`${style.ruleNr} ${style.rule6}`}>6</div>
              <p className={style.ruleTxt}>Thou shall not curse.</p>
            </li>
            <li className={style.rulesLine}>
              <div className={`${style.ruleNr} ${style.rule7}`}>7</div>
              <p className={style.ruleTxt}>Thou shall not understate.</p>
            </li>
            <li className={style.rulesLine}>
              <div className={`${style.ruleNr} ${style.rule8}`}>8</div>
              <p className={style.ruleTxt}>Thou shall not post soberly.</p>
            </li>
            <li className={style.rulesLine}>
              <div className={`${style.ruleNr} ${style.rule9}`}>9</div>
              <p className={style.ruleTxt}>
                Thou shall not pass on <br />
                the internal pin.
              </p>
            </li>
            <li className={style.rulesLine}>
              <div className={`${style.ruleNr} ${style.rule10}`}>10</div>
              <p className={style.ruleTxt}>
                Thou shall not post trash <br /> and bullshit.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
