import style from "../style/NavBar.module.css";
import Burger from "react-css-burger";
import { useState } from "react";
import "../style/libMod.css";

function NavBarAlt() {
  const [state, setState] = useState({
    active: false,
  });

  function NavCtrl() {
    var x = document.getElementById("nav");
    var y = document.getElementById("nav-root");
    var z = document.getElementById("dropdown");
    var v = document.getElementById("navbar");
    if (x.style.display !== "inherit") {
      x.style.display = "inherit";
      z.style.display = "none";
      v.style.justifyContent = "flex-end";

      y.style.overflow = "hidden";
      y.style.maxHeight = "100vh";
    } else {
      x.style.display = "none";
      z.style.display = "inherit";
      v.style.justifyContent = "space-between";
      y.style.overflow = "inherit";
      y.style.maxHeight = "100%";
    }
  }

  return (
    <div >
      <div className={style.navBar} id="navbar">
        <div className={style.dropDown} id="dropdown">
        </div>
        <Burger
          className={style.burgerMenu}
          onClick={() => {
            setState({ active: !state.active });
            NavCtrl();
          }}
          active={state.active}
          burger="vortex"
          color="white"
          hoverOpacity={0.8}
          scale={0.8}
        />
      </div>
      <div id="nav" className={style.nav}>
        <div className={style.navItem}>
        <a className={`${style.link}`} href="/about/1"> <p>ABOUT</p> </a>
        </div>
        <div className={style.navItem}>
        <a className={`${style.link} `} href="/rules/01"> <p>HOW TO PLAY</p> </a>
        </div>
        <div className={style.navItem}>
         <a className={style.link} href="/"> <p>CONFESSIONS</p> </a>
        </div>
        <div className={style.navItemSub}>
        <a className={`${style.link} `} href="/"><p>Privacy Policy</p></a>
        <a className={`${style.link}`} href="/"><p>Terms of Use</p></a>
        </div>
      </div>
    </div>
  );
}

export default NavBarAlt;
