import style from "../../style/Obj1.module.css";
import wineIcon from "../../assets/Icons/noun_Wine.svg";
import starIcon from "../../assets/Icons/noun_sparkling.svg";
import { useNavigate } from "react-router-dom";

export default function Ob3() {
  let navigate = useNavigate();

  function handleClickBack() {
    navigate("/2");
  }
  function handleClickRules() {
    navigate("/rules/1");
  }

  return (
    <div className={style.obj1root}>
      <img src={wineIcon} alt="wine" className={style.wineIcon} />
      <div className={style.container}>
        <div className={style.titleContainer}>
          <h1 className={style.title}>
            Hey
            <img src={starIcon} alt="star" className={style.starIcon} /> <br />
            Sinner
          </h1>
        </div>
        <div className={style.buttonContainerBack}>
          <button className={style.backButton} onClick={handleClickBack}>
            <p className={style.back}>Back</p>
          </button>
        </div>
        <div className={style.textContainer}>
          <p className={style.text}>
            From time to time, the best sins are evaluated and, of course,
            awarded. The rule: If you publicly confess your sin, an unbeatable
            price awaits you. You prefer to remain anonymous? Ok, party pooper,
            here’s a little goodie. <br /> <br />
            And most importantly: <br /> Have fun, be brave and AMEN.
          </p>
        </div>
        <div className={style.buttonContainer}>
          <button className={style.rulesButton} onClick={handleClickRules}>
            <p className={style.back}>Rules</p>
          </button>
        </div>
      </div>
    </div>
  );
}
