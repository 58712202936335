import { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import style from "../style/BrowseConfession.module.css";
import "../style/libMod.css";
import NavBar from "./NavBar";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { grey } from "@mui/material/colors";
import { useLocation, Navigate } from "react-router-dom";

function Landing() {
  let location = useLocation();

  if (
    location.pathname.includes("/") &&
    !localStorage.getItem("userLoggedin")
  ) {
    return <Navigate to="/0" />;
  } else if (
    !localStorage.getItem("user") &&
    !location.pathname.includes("/0") &&
    !location.pathname.includes("/1") &&
    !location.pathname.includes("/2") &&
    !location.pathname.includes("/3") &&
    !location.pathname.includes("/rules/1") &&
    !location.pathname.includes("/rules/2") &&
    !location.pathname.includes("/confess") &&
    location.pathname.includes("/") &&
    localStorage.getItem("userLoggedin")
  ) {
    return <Navigate to="/0" />;
  } else {
    return "";
  }
}

export default function BrowseConfession() {
  const [confessions, setConfessions] = useState([]);

  useEffect(() => {
    fetch(`${window.apiUrl}/confessions/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setConfessions(data);
      });
  }, []);

  return (
    <div className={style.background} id="nav-root">
      <NavBar />
      <div className={style.container} id="browse-root">
        {confessions?.map((confession) => {
          return (
            <Card className={style.card} key={confession?.id}>
              <CardContent className={style.cardContent}>
                <Typography>{confession?.Confession}</Typography>
              </CardContent>
            </Card>
          );
        })}
      </div>
      <a href="/new" className={style.addIcon}>
        <AddCircleRoundedIcon
          sx={{
            color: grey[50],
            fontSize: 90,
            background: grey[0],
            borderRadius: 25,
          }}
        ></AddCircleRoundedIcon>
      </a>
      <Landing />
    </div>
  );
}
