import style from "../../style/Obj1.module.css";
import wineIcon from "../../assets/Icons/noun_Wine.svg";
import starIcon from "../../assets/Icons/noun_sparkling.svg";
import { useNavigate } from "react-router-dom";

export default function Ob1() {
  let navigate = useNavigate();
  function handleClick() {
    navigate("/2");
  }

  return (
    <div className={style.obj1root}>
      <img src={wineIcon} alt="wine" className={style.wineIcon} />
      <div className={style.container}>
        <div className={style.titleContainer}>
          <h1 className={style.title}>
            Hey
            <img src={starIcon} alt="star" className={style.starIcon} /> <br />
            Sinner
          </h1>
        </div>
        <div className={style.buttonContainerNext}>
          <button className={style.nextButton} onClick={handleClick}>
            <p className={style.next}>Next</p>
          </button>
        </div>
        <div className={style.textContainer}>
          <p className={style.text}>
            On the path of his/her life, every person experiences not only doing
            good, but also frequently falling short of his/her own standards.
            Even - or especially - in the world of advertising, one is not
            protected from misconduct, unmercifulness and moral misgivings.{" "}
            <br />
            <br />
            Please take a seat at Oh!GUILTY - the digital agency confessional,
            officially powered by OGILVY Switzerland.
          </p>
        </div>
      </div>
    </div>
  );
}
