import style from "../../style/Obj1.module.css";
import starIcon from "../../assets/Icons/noun_sparkling.svg";
import { useNavigate } from "react-router-dom";
import NavBarAlt from "../NavBarAlt";

export default function Rules1() {
  let navigate = useNavigate();
  function handleClick() {
    navigate("/rules/02");
  }

  return (
    <div className={style.obj1root} id="nav-root">
      <NavBarAlt />
      <div className={style.container}>
        <div className={style.titleContainerRules}>
          <h1 className={style.titleRules}>
            THE 10 <br />
            COMMAND- <br />
            MENTS
            <img src={starIcon} alt="star" className={style.starIcon} /> <br />
          </h1>
        </div>
        <div className={style.buttonContainerNext}>
          <button className={style.nextButton} onClick={handleClick}>
            <p className={style.next}>Next</p>
          </button>
        </div>
        <div className={style.rulesContainer}>
          <ul>
            <li className={style.rulesLine}>
              <div className={`${style.ruleNr} ${style.rule1}`}>1</div>
              <p className={style.ruleTxt}>
                Thou shall not betray <br />
                thy neighbor.
              </p>
            </li>
            <li className={style.rulesLine}>
              <div className={`${style.ruleNr} ${style.rule2}`}>2</div>
              <p className={style.ruleTxt}>Thou shall not lie.</p>
            </li>
            <li className={style.rulesLine}>
              <div className={`${style.ruleNr} ${style.rule3}`}>3</div>
              <p className={style.ruleTxt}>
                Thou shall not spread <br /> rumors about your neighbor.
              </p>
            </li>
            <li className={style.rulesLine}>
              <div className={`${style.ruleNr} ${style.rule4}`}>4</div>
              <p className={style.ruleTxt}>Thou shall not discriminate.</p>
            </li>
            <li className={style.rulesLine}>
              <div className={`${style.ruleNr} ${style.rule5}`}>5</div>
              <p className={style.ruleTxt}>
                Thou shall only post <br /> agency-related confessions.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
