import style from "../../style/Obj1.module.css";
import starIcon from "../../assets/Icons/noun_sparkling_white.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function Toptitle() {
  return (
    <div className={style.toptitle}>
      <h1 className={style.title0}>
        <img src={starIcon} alt="star" className={style.starIcon0} /> Oh !{" "}
      </h1>
    </div>
  );
}

function BotTitle() {
  return (
    <div className={style.bottitle}>
      <h1 className={style.title00}>GUILTY</h1>
    </div>
  );
}

export default function Obj0() {
  const [step, setStep] = useState(0);
  let navigate = useNavigate();
  let lastStep = 1;

  let onNext = (e) => {
    setStep(step + 1);
    if (step === lastStep) {
      navigate("/1");
    }
  };

  return (
    <div className={style.obj1root}>
      <div className={style.container0} onClick={onNext}>
        {step === 0 && <Toptitle />}
        {step === 1 && <Toptitle />}
        {step === 1 && <BotTitle />}
      </div>
    </div>
  );
}
