import style from "../../style/Obj1.module.css";
import starIcon from "../../assets/Icons/noun_sparkling.svg";
import { useNavigate } from "react-router-dom";
import NavBarAlt from "../NavBarAlt";

export default function About2() {
  let navigate = useNavigate();
  function handleClick() {
    navigate("/about/3");
  }

  function handleClickBack() {
    navigate("/about/1");
  }
  return (
    <div className={style.obj1root} id="nav-root"> 
      <NavBarAlt />
      <div className={style.container}>
        <div className={style.titleContainer}>
          <h1 className={style.title}>
            Hey
            <img src={starIcon} alt="star" className={style.starIcon} /> <br />
            Sinner
          </h1>
        </div>
        <div className={style.buttonContainerBoth}>
          <button className={style.backButton} onClick={handleClickBack}>
            <p className={style.back}>Back</p>
          </button>
          <button className={style.nextButton} onClick={handleClick}>
            <p className={style.next}>Next</p>
          </button>
        </div>
        <div className={style.textContainer}>
          <p className={style.text}>
            As many of you probably know, we like to talk about ourselves and
            others – especially when we’re tipsy. <br />
            <br />
            Now we want to offer a platform to the „ourselves“. At Oh!GUILTY you
            can now anonymously confess your small (or even better the big)
            agency sins.
            <br />
            <br />
            As soon as you entered the individual pin and posted a sin, you can
            read and like the confessions of others.
          </p>
        </div>
      </div>
    </div>
  );
}
