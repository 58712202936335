import ConfessionBooth from "./ConfessionBooth.js";
import BrowseConfession from "./BrowseConfession.js";
import Ob0 from "./OnboardingSteps/Obj0.js";
import Ob1 from "./OnboardingSteps/Ob1.js";
import Ob2 from "./OnboardingSteps/Ob2.js";
import Ob3 from "./OnboardingSteps/Ob3.js";
import Rules1 from "./RuleSteps/Rules1.js";
import Rules2 from "./RuleSteps/Rules2.js";
import About1 from "./altAbout/About1.js";
import About2 from "./altAbout/About2.js";
import About3 from "./altAbout/About3.js";
import Rules01 from "./altRules/Rules1.js";
import Rules02 from "./altRules/Rules2.js";
import ConfessionBoothAlt from "./ConfessionBoothAlt.js";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";

function Landing() {
  const location = useLocation();
  console.log(location.pathname);
  if (!localStorage.getItem("user") && location.pathname.includes("/confess")) {
    fetch(`${window.apiUrl}/players/register`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("user", JSON.stringify(data));
      });
  }
  if (
    location.pathname.includes("/") &&
    !localStorage.getItem("user") &&
    !location.pathname.includes("/0") &&
    !location.pathname.includes("/1") &&
    !location.pathname.includes("/2") &&
    !location.pathname.includes("/3") &&
    !location.pathname.includes("/rules/1") &&
    !location.pathname.includes("/rules/2") &&
    !location.pathname.includes("/confess")
  ) {
    return <Navigate to="/0" />;
  } else {
    return "";
  }
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/0" element={<Ob0 />} />
        <Route path="/1" element={<Ob1 />} />
        <Route path="/2" element={<Ob2 />} />
        <Route path="/3" element={<Ob3 />} />
        <Route path="/rules/1" element={<Rules1 />} />
        <Route path="/rules/2" element={<Rules2 />} />
        <Route path="/confess" element={<ConfessionBooth />} />
        <Route exact path="/" element={<BrowseConfession />} />
        <Route path="/about/1" element={<About1 />} />
        <Route path="/about/2" element={<About2 />} />
        <Route path="/about/3" element={<About3 />} />
        <Route path="/rules/01" element={<Rules01 />} />
        <Route path="/rules/02" element={<Rules02 />} />
        <Route path="/new" element={<ConfessionBoothAlt />} />
      </Routes>
      <Landing />
    </Router>
  );
}

export default App;
